import React from "react"
import { graphql } from "gatsby"
import Hub from "../components/Hub.js"
import favicon from "../assets/favicon.png"
import defaultImage from "../assets/default-image.jpg"

const Homepage = ({ location, data }) => {
  //const homepage = data.prismicHomepage

  const slides = () => {
    // const items_shuffled = ((data.allPrismicNews.nodes).concat(data.allPrismicCreation.nodes)).sort((a, b) => 0.5 - Math.random());
    // return (data.allPrismicPage.nodes).concat(items_shuffled)
    return ((data.allPrismicPage.nodes).concat(data.allPrismicNews.nodes)).concat(data.allPrismicCreation.nodes)
  }
  
  return (
    <Hub 
      page={data.prismicHomepage} 
      slides={slides()} 
      location={location} 
      hub="homepage" 
    />
  )
}

export default Homepage

export const Head = ({ data }) => (
  <>
      <title>{`the sun project → ${data.prismicHomepage.lang.slice(0,2) === "fr" ? " Création d’expériences immersives et production 3D" : "Immersive Experiences Creators and 3D Production"}`}</title>
      <meta name="description" content={data.prismicHomepage.data.seo_description.text} />
      <meta name="keywords" content={data.prismicHomepage.data.seo_keywords.text} />
      <meta name="author" content="the sun project" />
      
      <meta property="og:title" content={`the sun project → ${data.prismicHomepage.lang.slice(0,2) === "fr" ? " Création d’expériences immersives et production 3D" : "Immersive Experiences Creators and 3D Production"}`} />
      <meta property="og:site_name" content="the sun project" />
      <meta property="og:description" content={data.prismicHomepage.data.seo_description.text} />
      <meta property="og:image" content={`https://thesunproject.fr${defaultImage}`} />
      <meta property="og:locale" content={data.prismicHomepage.lang} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="the sun project" />
      <meta name="twitter:title" content={`the sun project → ${data.prismicHomepage.lang.slice(0,2) === "fr" ? " Création d’expériences immersives et production 3D" : "Immersive Experiences Creators and 3D Production"}`} />
      <meta name="twitter:description" content={data.prismicHomepage.data.seo_description.text} />
      <meta name="twitter:image" content={`https://thesunproject.fr${defaultImage}`} />

      <link rel="icon" type="image/png" href={favicon} />
  </>
)

export const pageQuery = graphql`
  query HomepageBySlug($uid: String, $lang: String) {
    prismicHomepage(id: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      type
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
        seo_keywords {
          text
        }
        seo_description {
          text
        }
      }
    }
    allPrismicNews(
      filter: {lang: { eq: $lang }}
      sort: {fields: data___date, order: DESC}
      limit: 3
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          title {
            text
          }
          page_color
          date
        }
        type
      }
    }
    allPrismicCreation(
      filter: {lang: { eq: $lang }}
      sort: {fields: data___date, order: DESC}
      limit: 5
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          title {
            text
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 3000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          page_color
          client {
            text
          }
          date
          tags {
            tag {
              uid
            }
          }
        }
        type
      }
    }
    allPrismicPage(
      filter: {lang: { eq: $lang }, data: {home_visible: {eq: true }}}
      sort: {fields: data___home_order, order: ASC}
    ) {
      nodes {
        lang
        uid
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          title {
            text
          }
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 3000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          main_image_position
          page_color
          home_visible
          home_order
          home_title { 
            richText
          }
        }
        type
      }
    }
  }
`